import React from 'react'
import { ThumborUrlBuilder } from 'thumbor-url-builder-ts'

const thumbor = new ThumborUrlBuilder(ENV.THUMBOR_SECURITY, ENV.THUMBOR_URL)

type ImageFormat = {
  url: string
  width: number
  height: number
  optimizedUrl?: string
  optimizedWidth?: number
  optimizedHeight?: number
}

type UploadFormat = {
  large?: ImageFormat
  medium?: ImageFormat
  small?: ImageFormat
  xsmall?: ImageFormat
  xxsmall?: ImageFormat
  thumbnail?: ImageFormat
}

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  flipHorizontally?: boolean
  flipVertically?: boolean
  formats?: unknown
  skipSmallVariants?: boolean
}

export function Image({
  src,
  className,
  width,
  height,
  alt,
  loading = 'lazy',
  flipHorizontally = false,
  flipVertically = false,
  formats = {},
  skipSmallVariants = false,
}: ImageProps) {
  function createBuilder(url: URL, width?: number, height?: number) {
    const builder = thumbor.setImagePath(encodeURIComponent(url.toString()))

    if (width !== undefined && height !== undefined) {
      builder.resize(width, height).smartCrop(true)
    }

    if (flipHorizontally) {
      builder.withFlipHorizontally()
    }

    if (flipVertically) {
      builder.withFlipVertically()
    }

    return builder
  }

  let resultSrc = src

  const imageFormats = formats as UploadFormat

  imageFormats &&
    Object.keys(imageFormats).forEach(formatKey => {
      try {
        const format = imageFormats[formatKey as keyof UploadFormat]

        if (!format || !format.url) {
          return
        }

        const requestedUrl = new URL(format?.url ?? '')

        let cropWidth = 0
        let cropHeight = 0

        const imgWidth = Number(width) || 0
        const imgHeight = Number(height) || 0

        if (imgWidth > 0 && imgHeight > 0) {
          const resultingAspectRatio = imgWidth / imgHeight
          const formatAspectRatio = format.width / format.height

          if (resultingAspectRatio > formatAspectRatio) {
            cropHeight = format.height
            cropWidth = Math.round(cropHeight * resultingAspectRatio)
          } else {
            cropWidth = format.width
            cropHeight = Math.round(cropWidth / resultingAspectRatio)
          }
        }

        format.optimizedUrl = createBuilder(
          requestedUrl,
          cropWidth,
          cropHeight,
        ).buildUrl()

        format.optimizedWidth = cropWidth ? cropWidth : format.width
        format.optimizedHeight = cropHeight ? cropWidth : format.height
      } catch (_) {}
    })

  try {
    const requestedUrl = new URL(src ?? '')

    resultSrc = createBuilder(
      requestedUrl,
      Number(width) ?? 0,
      Number(height) ?? 0,
    ).buildUrl()
  } catch (_) {}

  function resolveDimension(dimension: string | number | undefined) {
    return !dimension || dimension === 0 ? undefined : dimension
  }

  return (
    <>
      <picture>
        {imageFormats?.thumbnail?.optimizedUrl && !skipSmallVariants && (
          <source
            srcSet={`${imageFormats.thumbnail.optimizedUrl} ${imageFormats.thumbnail.optimizedWidth}w`}
            media={`(max-width: ${imageFormats.thumbnail.optimizedWidth}px)`}
            width={resolveDimension(imageFormats.thumbnail.optimizedWidth)}
            height={resolveDimension(imageFormats.thumbnail.optimizedHeight)}
          />
        )}
        {imageFormats?.xxsmall?.optimizedUrl && !skipSmallVariants && (
          <source
            srcSet={`${imageFormats.xxsmall.optimizedUrl} ${imageFormats.xxsmall.optimizedWidth}w`}
            media={`(max-width: ${imageFormats.xxsmall.optimizedWidth}px)`}
            width={resolveDimension(imageFormats.xxsmall.optimizedWidth)}
            height={resolveDimension(imageFormats.xxsmall.optimizedHeight)}
          />
        )}
        {imageFormats?.xsmall?.optimizedUrl && !skipSmallVariants && (
          <source
            srcSet={`${imageFormats.xsmall.optimizedUrl} ${imageFormats.xsmall.optimizedWidth}w`}
            media={`(max-width: ${imageFormats.xsmall.optimizedWidth}px)`}
            width={resolveDimension(imageFormats.xsmall.optimizedWidth)}
            height={resolveDimension(imageFormats.xsmall.optimizedHeight)}
          />
        )}
        {imageFormats?.small?.optimizedUrl && !skipSmallVariants && (
          <source
            srcSet={`${imageFormats.small.optimizedUrl} ${imageFormats.small.optimizedWidth}w`}
            media={`(max-width: ${imageFormats.small.optimizedWidth}px)`}
            width={resolveDimension(imageFormats.small.optimizedWidth)}
            height={resolveDimension(imageFormats.small.optimizedHeight)}
          />
        )}
        {imageFormats?.medium?.optimizedUrl && !skipSmallVariants && (
          <source
            srcSet={`${imageFormats.medium.optimizedUrl} ${imageFormats.medium.optimizedWidth}w`}
            media={`(max-width: ${imageFormats.medium.optimizedWidth}px)`}
            width={resolveDimension(imageFormats.medium.optimizedWidth)}
            height={resolveDimension(imageFormats.medium.optimizedHeight)}
          />
        )}
        {imageFormats?.large?.optimizedUrl && !skipSmallVariants && (
          <source
            srcSet={`${imageFormats.large.optimizedUrl} ${imageFormats.large.optimizedWidth}w`}
            media={`(max-width: ${imageFormats.large.optimizedWidth}px)`}
            width={resolveDimension(imageFormats.large.optimizedWidth)}
            height={resolveDimension(imageFormats.large.optimizedHeight)}
          />
        )}
        <img
          className={className}
          src={resultSrc}
          alt={alt}
          width={resolveDimension(width)}
          height={resolveDimension(height)}
          loading={loading}
          decoding="async"
        />
      </picture>
    </>
  )
}
